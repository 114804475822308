.container-control {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 20px 0;
    min-height: 100vh;

    background: linear-gradient(to bottom,
            rgb(184, 184, 184) 0,
            rgb(0, 0, 0));
}

.appt-card {
    // border: 1px solid black;
    display: flex;
    flex-direction: column;
    width: 60%;
    padding: 5px 10px;
    margin: 5px 5px;
    background-color: #4A0000;
    color: white;
    border-radius: 0.2em;
    box-shadow: 0px 0px 5px 1px black;

    .link {
        text-decoration: none;
        color: rgb(146, 146, 146);;
    }

    .link:hover {
        color: white;
    }
    
    
    * {
        margin: 5px 5px;
        font-weight: lighter;
    }

    .more-info {
        display: flex;
        border: 1px solid white;
        justify-content: center;
        border-radius: 0.2em;
        margin-bottom: 10px;
        cursor: pointer;

        &:hover {
            background-color: rgba(255, 255, 255, 0.31);
            transition: 0.5s;
        }

    }

    ul {
        display: none;
        // background-color: black;
        list-style: none;
        // margin: 0;
        padding: 5px 15px;
        border-radius: 0.6em;
        width: 100%;

        li {
            border-bottom: 1px solid #4d4c4c ;
            width: 70%;
            margin-top: 10px;
        }
    }
}

.day-filter {
    margin: 50px 0px;
    // border: 1px solid;
    width: 100vw;

    form {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        // border: 1px solid;
    }
    
    label {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight:lighter;
        font-size: 35px;
        // margin-left: 50px;

        input, select {
            margin-top: 20px;
            height: 40px;
            padding-left: 10px;
            border-radius: 0.2em;
            // border: 1px solid black;
            border: none;
            box-shadow: 0px 0px 5px 0px;
            font-size: 25px;
        }
    }
}

.transaction-id {
    font-size: 13px;
    margin-left: 20px;
}

.hour {
    display: flex;
    // border: 1px solid black;
    width: 70%;
    justify-content: space-between;
    margin-left: 15px;
    background-color: white;
    color: #4A0000;
    border-radius: 0.2em;
}

.details-list.active {
    display: block;
    top: 0;
    transition: 0.5s ease;

    .wpp-link {
        color: white;
        text-decoration: none;

        &:hover {
            color:#4d4c4c;
        }
    }   

    .cancel-app {
        border: none;
        color: #4A0000;
        padding: 10px 10px;
        text-transform: uppercase;
        cursor: pointer;
        margin-top: 15px;
        margin-bottom: 1px;
        font-weight:600;
        border-radius: 0.2em;

        &:hover {
            background-color: #d49a9a;
            transition: .2s;
        }
    }
}

@media screen and (max-width: 840px){ 
    .appt-card {
        width: 80%;
    }

    .hour {
        width: 90%;
    }

    .day-filter {
        form {
            flex-direction: column;
        }

        .select_professional_filter {
            margin-top: 30px;
        }
    }
}