.container-home {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, rgb(184, 184, 184) 0, rgb(0, 0, 0));
}

@media screen and (max-width: 840px) {
  .container-home {
    height: -moz-fit-content;
    height: fit-content;
    padding: 20px 0;
  }
}/*# sourceMappingURL=Home.css.map */