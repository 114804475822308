.signup {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom, rgb(184, 184, 184) 0, rgb(0, 0, 0));
    flex-direction: column;
}

@media screen and (max-width: 840px) {
    .signup {
        height: fit-content;
        padding: 20px 0;
    }
}