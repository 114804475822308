.login-form-section {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 90%;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-weight: 100;
  background-color: #4A0000;
  color: white;
  border-radius: 0.2em;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.612);
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.login-form label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}
.login-form label span {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.442);
}
.login-form label input {
  margin-top: 10px;
  width: 75%;
  min-width: 100px;
  height: 30px;
  text-align: center;
  border: none;
  color: #ffffff;
  padding: 10px 10px;
  background-color: #353535;
  box-shadow: 0px 0px 5px 1px rgb(39, 39, 39);
  font-size: 16px;
  border-radius: 0.2em;
}
.login-form label input:disabled {
  background-color: #555555;
}
.login-form label select {
  width: 180px;
  margin-top: 10px;
  height: 50px;
  text-align: center;
  border: none;
  color: #ffffff;
  padding: 10px 10px;
  background-color: #353535;
  box-shadow: 0px 0px 5px 1px rgb(39, 39, 39);
  border-radius: 0.2em;
}
.login-form button {
  border: none;
  color: #4A0000;
  padding: 10px 10px;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 30px;
  font-weight: 600;
  width: 80%;
  border-radius: 0.2em;
  box-shadow: 0px 0px 10px 1px rgb(39, 39, 39);
  background-color: white;
}
.login-form button:hover {
  background-color: #d49a9a;
  transition: 0.2s;
}

@media screen and (max-width: 840px) {
  .form-section {
    width: 100%;
  }
  .form-section .login-form input {
    width: 250px;
  }
  .form-section .login-form .service {
    width: 75%;
  }
  .form-section .login-form .service select {
    width: 130px;
  }
  .form-section .login-form button {
    width: 70%;
  }
}/*# sourceMappingURL=login-form.css.map */