.dayoff_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-top: 50px;
  width: 100%;
  box-sizing: border-box;
}

.sched-dayoff {
  display: flex;
  flex-direction: column;
  // border: 1px solid;
  width: 90%;
  padding: 20px 20px;
  padding-bottom: 0px;
  background-color: #4A0000;
  color: white;
  border-radius: 0.2em;
  box-shadow: 0 0 10px 1px black;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.431);

  .white-bg {
      background-color: white;
      border-radius: 0.2em;
      padding-bottom: 20px;
      margin-bottom: 20px;
      color: #4A0000;
  }

  .set-margin {
      padding: 10px 10px;
      padding-bottom: 0px;
  }

  h3 {
      margin: 0;
      margin-bottom: 5px
  }

  p {
      margin-bottom: 10px;
  }

  input, select {
      width: 150px;
      height: 40px;
      text-align: center;
      border: none;
      background-color: #353535;
      color: white;
      border-radius: 0.2em;
      box-shadow: 0px 0px 5px .001em black;
      margin-top: 5px;
      // margin-left: 20px;
  }

  input:disabled {
      background-color: #555555;
  }

  label {
      margin-left: 30px;
  }
  button {
    width: 150px;
    height: 40px;
    text-align: center;
    border: none;
    background-color: #353535;
    color: white;
    border-radius: 0.2em;
    box-shadow: 0px 0px 5px .001em black;
    // margin-top: 5px;
    // margin-left: 20px;
  }
  .inp-sub {
      background-color: #4A0000;
      color: white;
      width: 100px;
      margin-left: 30px;
      cursor: pointer;
      align-self: end;
      // margin-top: 30px;

      &:hover {
          background-color: #d49a9a;
          transition: .2s;
      }
  }
  .inp_sub_cancel {
      background-color: #4A0000;
      width: 100px;
      height: 20px;
      margin-left: 10px;
      cursor: pointer;
      align-self: end;
      color: white;
      border-radius: 0.2em;
      box-shadow: 0px 0px 5px .001em black;
      font-size: 10px;
      // margin-top: 30px;

      &:hover {
          background-color: #d49a9a;
          transition: .2s;
      }
  }
}

.input-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.font-normal {
  font-weight: lighter;
}

.day_off_date_input {
  margin-bottom: 10px;
}

@media screen and (max-width: 840px) {
  .sched-dayoff {
      width: 80%;
  }

  // .input-container {
  //     flex-direction: column;
  // }
}