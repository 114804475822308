.form-error {
    position: absolute;
    display: flex;
    // border: 3px solid black;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 300px;
    background-color: #4A0000;
    color: white;
    max-width: 300px;
    box-shadow: 0px 0px 10px 1px black;
    border-radius: 0.2em;
    text-shadow: none;
  
    .error-message {
      margin: 15px;
    }
  
    .close-error {
      display: flex;
      position: absolute;
      top: 15px;
      right: 15px;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: rgb(68, 68, 68);
      border-radius: 0.2em;
      color: white;
      font-weight: bold;
      cursor: pointer;
      box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.613);
    }
  
    .white-block {
      display: flex;
      justify-content: left;
      text-align: left;
      align-items: center;
      background-color: white;
      width: 100%;
      height: 100%;
      max-width: 80%;
      max-height: 80%;
      color: #4A0000;
      border-radius: 0.2em;
      font-size: larger;
      border: none;
    }
  }