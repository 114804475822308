.schedule-form-section {
    display: flex;
    flex-direction: column;
    width: 500px;
    justify-content: center;
    align-items: center;
    background-color: #4A0000;
    color: white;
    border-radius: 0.2em;
    box-shadow: 0px 0px 10px 0.01em rgb(16, 16, 16);
    // border: 0.1rem solid black;

    h1 {
        margin-top: 40px;
        margin-bottom: 0px;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.442);
    }
}

.appointment-form {
    display: flex;
    flex-direction: column;
    width: 400px;
    align-items: center;
    margin-top: 20px;
    font-weight: 100;

    label {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        span {
            text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.442);
        }


        input {
            margin-top: 10px;
            width: 400px;
            height: 30px;
            text-align: center;
            border: none;
            color: #ffffff;
            padding: 10px 10px;
            background-color: #353535;
            box-shadow: 0px 0px 5px 1px rgb(39, 39, 39);
            font-size: 16px;
            border-radius: 0.2em;

            &:disabled {
                background-color: #555555;
            }
        }

        select {
            width: 180px;
            margin-top: 10px;
            height: 50px;
            text-align: center;
            border: none;
            color: #ffffff;
            padding: 10px 10px;
            background-color: #353535;
            box-shadow: 0px 0px 5px 1px rgb(39, 39, 39);
            border-radius: 0.2em;
        }

    }

    .service {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
    }

    button {
        border: none;
        color: #4A0000;
        padding: 10px 10px;
        text-transform: uppercase;
        cursor: pointer;
        margin-top: 20px;
        margin-bottom: 30px;
        font-weight: 600;
        width: 80%;
        border-radius: 0.2em;
        box-shadow: 0px 0px 10px 1px rgb(39, 39, 39);

        &:hover {
            background-color: #d49a9a;
            transition: .2s;
        }
    }
}

@media screen and (max-width: 840px) {
    .schedule-form-section {
        width: 95%;
        max-width: 500px;

        .appointment-form {

            width: 100%;
            
            input {
                width: 300px;
            }

            .service {
                width: 85%;
                // border: solid;
                

                select {
                    width: 130px;
                }

            }

            button {
                width: 80%;
            }
        }
    }

}