.container-control {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  min-height: 100vh;
  background: linear-gradient(to bottom, rgb(184, 184, 184) 0, rgb(0, 0, 0));
}

.appt-card {
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 5px 10px;
  margin: 5px 5px;
  background-color: #4A0000;
  color: white;
  border-radius: 0.2em;
  box-shadow: 0px 0px 5px 1px black;
}
.appt-card .link {
  text-decoration: none;
  color: rgb(146, 146, 146);
}
.appt-card .link:hover {
  color: white;
}
.appt-card * {
  margin: 5px 5px;
  font-weight: lighter;
}
.appt-card .more-info {
  display: flex;
  border: 1px solid white;
  justify-content: center;
  border-radius: 0.2em;
  margin-bottom: 10px;
  cursor: pointer;
}
.appt-card .more-info:hover {
  background-color: rgba(255, 255, 255, 0.31);
  transition: 0.5s;
}
.appt-card ul {
  display: none;
  list-style: none;
  padding: 5px 15px;
  border-radius: 0.6em;
  width: 100%;
}
.appt-card ul li {
  border-bottom: 1px solid #4d4c4c;
  width: 70%;
  margin-top: 10px;
}

.day-filter {
  margin: 50px 0px;
  width: 100vw;
}
.day-filter form {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.day-filter label {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: lighter;
  font-size: 35px;
}
.day-filter label input, .day-filter label select {
  margin-top: 20px;
  height: 40px;
  padding-left: 10px;
  border-radius: 0.2em;
  border: none;
  box-shadow: 0px 0px 5px 0px;
  font-size: 25px;
}

.transaction-id {
  font-size: 13px;
  margin-left: 20px;
}

.hour {
  display: flex;
  width: 70%;
  justify-content: space-between;
  margin-left: 15px;
  background-color: white;
  color: #4A0000;
  border-radius: 0.2em;
}

.details-list.active {
  display: block;
  top: 0;
  transition: 0.5s ease;
}
.details-list.active .wpp-link {
  color: white;
  text-decoration: none;
}
.details-list.active .wpp-link:hover {
  color: #4d4c4c;
}
.details-list.active .cancel-app {
  border: none;
  color: #4A0000;
  padding: 10px 10px;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 1px;
  font-weight: 600;
  border-radius: 0.2em;
}
.details-list.active .cancel-app:hover {
  background-color: #d49a9a;
  transition: 0.2s;
}

@media screen and (max-width: 840px) {
  .appt-card {
    width: 80%;
  }
  .hour {
    width: 90%;
  }
  .day-filter form {
    flex-direction: column;
  }
  .day-filter .select_professional_filter {
    margin-top: 30px;
  }
}/*# sourceMappingURL=agenda.css.map */