.footer {
  display: flex;
  max-width: 100%;
  height: 60px;
  padding: 15px 0;
  position: block;
  bottom: 0;
  color: white;
  background-color: black;
  justify-content: center;
  align-items: center;
}/*# sourceMappingURL=footer.css.map */