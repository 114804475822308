.header {
    // width: 100%;
    max-width: 100%;
    height: 60px;
    background-color: black;
    color: white;
    padding: 15px 0px;
    
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;

    position:block;
    top: 0;
    z-index: 999;
    
    a {
        display: block;
        text-decoration: none;
        color: white;
        margin-left: 20px;
        font-size: 18px;
        
        &:hover {
            color: #4A0000;
            transition: 0.5s ease;
        }
    }
}
.hamburger {
    display: none;
    // width: 30px;
    // height: 30px;
    background-color: transparent;
    border-radius: 5px;
    border: 0;
    margin: 20px 20px;
    // position: relative;
    cursor: pointer;

    img {
        width: 45px;
    }
}

.navbar {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // padding: 10px;
    margin-top: 0px;
    // padding-bottom: 10px;
}

.menu {
    // flex:4;
    display: flex;
    height: 100%;
    // justify-content: right;
    align-items: center;
    padding: 30px 30px;
}

.logo {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // margin-left: 0;
    // // padding-top: 100px;
    // padding: 0 10px;
    // border-radius: 0.2em;
    // border: 1px solid green;
    
    h1 {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Jomhuria', serif;
        font-weight: lighter;
        font-size: 35px;
        margin: 0;
        padding: 0;
        padding-top: 10px;
        color: black;
        // border: solid;
        
        &:hover {
            color: #4A0000;
            transition: 0.5s ease;
        }
    }
}

.header-logo {
    width: 125px;
    margin-left: 0;
    padding-top: 15px;
    // border: 1px solid green;
    // padding: 20px 20px;
}

@media screen and (max-width: 980px) {
    .hamburger {
        display: block;
        z-index: 999;
    }

    .menu {
        position: fixed;
        flex-direction: column;
        justify-content: center;
        top: 0;
        right: -100%;
        z-index: 998;
        // width: 200px;


        height: 100vh;
        background-color: #222;

        width: 100%;
        max-width: 200px;

        padding: 100px 20px;

        transition: .5s ease;

        a {
            padding-top: 20px;
        }

        
        
    }
    .menu.active {
        right: 0;
    }
}