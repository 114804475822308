.App {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: black;
}

.logo-bg {
  width: 90%;
  position: absolute;
  z-index: -1;
  align-self: center;
  border: 1px solid;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100% */
}